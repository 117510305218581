<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      :label="$t('COMMON.ORGANIZATION')"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <organization-selector
        :organization="tax.organization.id"
        :filterable="true"
        :showAll="false"
        :allowNone="true"
        @organizationChanged="
          (organizationId) => {
            tax.organization.id = organizationId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-checkbox
      v-model="tax.active"
      class="mb-3"
      @change="
        () => {
          onFormChanged();
        }
      "
    >
      <span class="form-control-label">{{ $t("TAXES.ACTIVE") }}</span>
    </base-checkbox>
    <validation-error :errors="apiValidationErrors.active" />

    <base-input
      :label="`${$t('COMMON.NAME')} (*)`"
      :placeholder="$t('COMMON.NAME')"
      v-model="tax.name"
      @change="onFormChanged"
    />
    <validation-error :errors="apiValidationErrors.name" />

    <base-input
      :label="`${$t('TAXES.LABEL')} (*)`"
      :placeholder="$t('TAXES.LABEL')"
      v-model="tax.label"
      @change="onFormChanged"
    />
    <validation-error :errors="apiValidationErrors.label" />

    <base-input
      :label="`${$t('TAXES.TAX_NUMBER')}`"
      :placeholder="$t('TAXES.TAX_NUMBER')"
      v-model="tax.tax_number"
      @change="onFormChanged"
    />
    <validation-error :errors="apiValidationErrors.tax_number" />

    <base-input :label="`${$t('TAXES.TAX_TYPE')} (*)`">
      <el-select
        class="select-primary"
        v-model="tax.tax_type"
        :placeholder="$t('TAXES.TAX_TYPE')"
        @change="onFormChanged"
      >
        <el-option
          class="select-primary"
          v-for="(item, key) in taxTypeOptions"
          :key="key"
          :label="$t(`TAXES.TAX_TYPE_${item}`)"
          :value="item"
        >
        </el-option>
      </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.tax_type" />

    <base-input :label="`${$t('TAXES.TAX_CALCULATION_TYPE')} (*)`">
      <el-select
        class="select-primary"
        v-model="tax.calculation_type"
        :placeholder="$t('TAXES.TAX_CALCULATION_TYPE')"
        @change="onFormChanged"
      >
        <el-option
          class="select-primary"
          v-for="(item, key) in taxCalculationType"
          :key="key"
          :label="$t(`TAXES.TAX_CALCULATION_TYPE_${item}`)"
          :value="item"
        >
        </el-option>
      </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.calculation_type" />

    <base-input :label="`${$t('TAXES.TAX_CALCULATION_BASE')} (*)`">
      <el-select
        class="select-primary"
        v-model="tax.calculation_base"
        :placeholder="$t('TAXES.TAX_CALCULATION_BASE')"
        @change="onFormChanged"
      >
        <el-option
          class="select-primary"
          v-for="(item, key) in taxCalculationBase"
          :key="key"
          :label="$t(`TAXES.TAX_CALCULATION_BASE_${item}`)"
          :value="item"
        >
        </el-option>
      </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.calculation_base" />

    <base-input
      :label="`${$t('TAXES.VALUE')} (*)`"
      :placeholder="$t('TAXES.VALUE')"
      v-model="tax.value"
      type="number"
      step="0.0001"
      @change="onFormChanged"
    />
    <validation-error :errors="apiValidationErrors.value" />

    <base-input
      :label="$t('COMMON.EXCERPT')"
      :placeholder="$t('COMMON.EXCERPT')"
    >
      <html-editor v-model="tax.excerpt" @change="onFormChanged()">
      </html-editor>
    </base-input>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{ tax.id ? $t("TAXES.EDIT_TAX") : $t("TAXES.ADD_TAX") }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import models from "@/constants/models";
import {
  taxTypeOptions,
  taxCalculationType,
  taxCalculationBase,
} from "@/constants/taxes";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    OrganizationSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["taxData", "formErrors", "loading"],

  data() {
    let taxData = { ...this.taxData };
    taxData = this.$fillUserOrganizationData(taxData);
    return {
      tax: taxData,
      models: models,
      taxTypeOptions: taxTypeOptions,
      taxCalculationType: taxCalculationType,
      taxCalculationBase: taxCalculationBase,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let taxData = cloneDeep(this.tax);
      this.$emit("taxesubmitted", taxData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    taxData(taxData) {
      if (taxData) {
        this.tax = { ...this.tax, ...cloneDeep(taxData) };
        if (!this.tax.organization) {
          this.tax.organization = {
            type: "organizations",
            id: null,
          };
        }
      }
    },
  },
};
</script>
