export const TAX_TYPE_SALES = "SALES";
export const TAX_TYPE_PURCHASES = "PURCHASES";

export const TAX_CALCULATION_TYPE_AMOUNT = "AMOUNT";
export const TAX_CALCULATION_TYPE_PERCENTAGE = "PERCENTAGE";

export const TAX_CALCULATION_BASE_BEFORE_TAX = "BEFORE_TAX";
export const TAX_CALCULATION_BASE_AFTER_TAX = "AFTER_TAX";

export const taxTypeOptions = [TAX_TYPE_SALES, TAX_TYPE_PURCHASES];
export const taxCalculationType = [
  TAX_CALCULATION_TYPE_AMOUNT,
  TAX_CALCULATION_TYPE_PERCENTAGE,
];
export const taxCalculationBase = [
  TAX_CALCULATION_BASE_BEFORE_TAX,
  TAX_CALCULATION_BASE_AFTER_TAX,
];
