export default {
  active: true,
  type: "taxes",
  name: null,
  label: null,
  tax_number: null,
  tax_type: null,
  calculation_type: null,
  calculation_base: null,
  value: null,
  excerpt: "",
  relationshipNames: ["organization"],
  organization: {
    type: "organizations",
    id: null,
  },
};
